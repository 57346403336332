<template>
  <div style="background: #F8FAFB" class="text-left">
    <NavBarNew :background="true"/>
    <ProfileNavBar />
    <main>
        <section class="tr-container tr-glasses-login">
            <h1 class="tr__h1">{{ $t("public.myTravelTipsPage.myTrips") }}</h1>
            <div class="filter-buttons">
                <div class="w-100 mb-5">
                    <!-- <button class="state-pick-button" :class="{active: filter.progressState === 'IN_PROGRESS'}" @click="setFilterState('IN_PROGRESS')">Probíhající</button> -->
                    <button class="state-pick-button" :class="{active: filter.liked}" @click="setFilterState('LIKED')">{{ $t("public.myTravelTipsPage.favourites") }}</button>
                    <!-- <button class="state-pick-button" :class="{active: filter.progressState === 'FINISHED'}" @click="setFilterState('FINISHED')">Minulé</button> -->
                </div>
                <div class="filter-icon-container" @click="isSidebarVisible=true">
                    <img src="@/assets/svg/icon-filter.svg" class="filter-icon" alt="filter">
                    <div v-if="activeFiltersCount > 0" class="filter-count">{{ activeFiltersCount }}</div>
                </div>
            </div>
            <div v-if="myTripsTravelTips && myTripsTravelTips.length < 1 && !$apollo.loading && $store.state.sliderMaxValue">
                <div v-if="!filter.liked || activeFiltersCount > 0" class="no-travel-tips-alert-container">
                    <img src="@/assets/svg/icon-not-found.svg" alt="">
                    <p>{{ $t("public.myTravelTipsPage.noTravelTips") }}</p>
                    <p>{{ $t("public.myTravelTipsPage.tryToClearFilter") }}</p>
                    <TrekioButton secondary @click="setDefaultFilter">{{ $t("public.myTravelTipsPage.clearFilter") }}</TrekioButton>
                </div>
                <div v-else class="no-travel-tips-alert-container">
                    <img src="@/assets/svg/icon-not-found.svg" alt="">
                    <p>{{ $t("public.myTravelTipsPage.noTravelTips") }}</p>
                    <p>{{ $t("public.myTravelTipsPage.findYourTrips") }}</p>
                    <router-link to="/">
                        <button class="button button--primary">
                            {{ $t("public.myTravelTipsPage.browseTrips") }}
                        </button>
                    </router-link>
                </div>
            </div>
            <div v-if="!hasFilterChanged" class="travel-tip-list-container">
                <div v-for="travelTip in myTripsTravelTips" :key="travelTip.id" class="travel-tip">
                    <router-link
                                    :to="{name:'travelTipDetail', params:{travelTipId:travelTip.id}}">
                        <TripItem :trip='travelTip' :isMyTravelTip="true" @refetchMyTravelTips="refreshResult"/>
                    </router-link>
                </div>
            </div>
            <div class="page-loading-container">
                <div v-if="$apollo.loading || !$store.state.sliderMaxValue" class="loader-1 center paging-loading"><span></span></div>
            </div>
        </section>
    </main>
    <Footer style="margin-top: 60px"/>
    <Sidebar  :isVisible="isSidebarVisible" @closeSidebar="isSidebarVisible=false" :title="$t('public.myTravelTipsPage.filterTrips')">
        <div class="filter-sidebar">
            <div class="filters-container text-left d-flex flex-column" style="gap: 30px">
                <TrekioInput dark-bg id="serach" searchInput v-model="filter.searchString" :placeholder="$t('general.search')"/>
                <TrekioSelect dark-bg v-model="filter.transferTypes" selectAllOption :isAllSelected="filter.transferTypes.length == transferTypes.length" multiple
                    :text="filter.transferTypes.length === 1 ? translate(filter.transferTypes[0], 'transferTypes') : $t('general.transport')"
                >

                    <TrekioSelectOption v-for="transfer in transferTypes" :key="transfer.value" :value="transfer.value">{{ transfer.text }}</TrekioSelectOption>
                </TrekioSelect>
                <TrekioSelect dark-bg v-model="filter.countries" selectAllOption :isAllSelected="filter.countries.length == countries.length" multiple
                    :text="filter.countries.length === 1 ? translate(filter.countries[0], 'countries') : $t('general.countries')"
                    searchInput @searchInput="value => countriesSearchString = value" :searchString="countriesSearchString"
                >

                    <TrekioSelectOption v-for="country in filteredCountries" :key="country.code" :value="country.code">{{ country[countryLocalization] }}</TrekioSelectOption>
                </TrekioSelect>
                <TrekioSelect dark-bg v-model="filter.themes" selectAllOption :isAllSelected="filter.themes.length == themes.length" multiple
                    :text="filter.themes.length === 1 ? translate(filter.themes[0], 'themes') : $t('general.theme')"
                >

                    <TrekioSelectOption v-for="theme in themes" :key="theme.value" :value="theme.value">{{ theme.text }}</TrekioSelectOption>
                </TrekioSelect>
                <TrekioSelect dark-bg v-model="filter.difficulties" selectAllOption :isAllSelected="filter.difficulties.length == difficulties.length" multiple
                    :text="filter.difficulties.length === 1 ? translate(filter.difficulties[0], 'difficulties') : $t('general.difficulty')"
                >

                    <TrekioSelectOption v-for="difficulty in difficulties" :key="difficulty.value" :value="difficulty.value">{{ difficulty.text }}</TrekioSelectOption>
                </TrekioSelect>
            </div>
            <div class="buttons-container">
                <TrekioButton secondary class="mb-4" @click="setDefaultFilter">{{ $t("public.myTravelTipsPage.clearFilter") }}</TrekioButton>
                <TrekioButton primary @click="isSidebarVisible = false">{{ $t("buttons.show") }}</TrekioButton>
            </div>
        </div>
    </Sidebar>
</div>
</template>

<script>
const SINGLE_PAGE_SIZE = 20

import Footer from "@/components/Footer";
import ProfileNavBar from "../components/ProfileNavBar.vue";
import { MY_TRAVEL_TIPS, MY_TRAVEL_TIPS_FOR_COUNTRIES, TRAVEL_TIPS_WITH_DAYCOUNT_ONLY } from "../api/graphql/query/TravelTipQuery";
import TripItem from "@/components/redesign/TripItem";
import Sidebar from "../components/Sidebar.vue";
import sharedUtils from '@/utils/sharedUtils';
import { assignTravelTipLocalizationProperties } from "../utils/LocalizationDataConverter";

    export default {
        name: "MyTravelTipsPage",

        components: {
            Footer,
            ProfileNavBar,
            TripItem,
            Sidebar
        },
        
        data: function() {
            return {
                myTripsTravelTips: [],
                countries: [],
                countriesSearchString: '',
                cbAllSelected: {
                    countries: false,
                    difficulties: false,
                    themes: false
                },
                isSidebarVisible: false,
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: '',
                },
                filter: {
                    countries: [],
                    themes: [],
                    difficulties: [],
                    transferTypes: [],
                    minLengthOfTravel: 1,
                    maxLengthOfTravel: this.$store.state.sliderMaxValue,
                    searchString: null,
                    liked: true,
                    progressState: null,
                },
                pageSize: {
                    page: 0,
                    size: SINGLE_PAGE_SIZE,
                    sortAttribute: "VIEW_COUNT",
                    sortDirection: "DESC"
                },
                isMoreToFetch: true,
                hasFilterChanged: true,
            }
        },

        computed: {
            themes() {
                return [
                    {value: 'FOOD', text: this.$t("general.food")}, 
                    {value: 'TOURISM', text: this.$t("general.nature")}, 
                    {value: 'SPORT', text: this.$t("general.adventure")}, 
                    {value: 'CULTURE', text: this.$t("general.culture")}
                ]
            },
            difficulties() {
                return [
                    {value: 'LOW', text: this.$t("general.low")}, 
                    {value: 'MEDIUM', text: this.$t("general.medium")}, 
                    {value: 'HIGH', text: this.$t("general.high")}
                ]
            },
            transferTypes() {
                return [
                    {value: 'WALK',text: this.$t("general.walk")},
                    {value: 'PUBLIC_TRANSPORT',text: this.$t("general.publicTransport")},
                    {value: 'BUS',text: this.$t("general.bus")}, 
                    {value: 'TRAIN',text: this.$t("general.train")},
                    {value: 'BIKE',text: this.$t("general.bike")},
                    {value: 'ROLLER_SCATE',text: this.$t("general.rollerScate")},
                    {value: 'SCOOTER',text: this.$t("general.scooter")},
                    {value: 'BOAT',text: this.$t("general.boat")},
                    {value: 'CABLE_CAR',text: this.$t("general.cableCar")},
                    {value: 'CAR',text: this.$t("general.car")},
                    {value: 'FLIGHT',text: this.$t("general.flight")}
                ]
            },
            activeFiltersCount() {
                let totalCount = 0
                totalCount += this.filter.countries.length
                totalCount += this.filter.themes.length
                totalCount += this.filter.transferTypes.length
                totalCount += this.filter.difficulties.length
                totalCount += this.filter.minLengthOfTravel !== 1 ? 1 : 0
                totalCount += this.filter.maxLengthOfTravel !== this.$store.state.sliderMaxValue ? 1 : 0
                totalCount += this.filter.searchString ? 1 : 0
                return totalCount
            },
            filteredCountries() {
                if(this.countries) {
                    let countries = [...this.countries]
                    const searchString = sharedUtils.removeAccents(this.countriesSearchString)
                    return countries.filter(c => {
                        if (sharedUtils.removeAccents(c[this.countryLocalization]).toUpperCase().includes(searchString.toUpperCase())) return true
                        return false
                    })
                }
                return []
            },
            countryLocalization() {
                return this.$store.getters.preferredLanguage == "en" ? "nameEnglish" : "nameCzech"
            },
        },

        methods: {
            handleScroll() {
                if (!this.isMoreToFetch || this.$apollo.loading) return
                const scrollThreshold = 900;
                
                if (window.innerHeight + window.scrollY >= document.body.offsetHeight - scrollThreshold) {
                    this.fetchMoreTravelTips();
                }
            },
            fetchMoreTravelTips () {
                if (!this.filter.maxLengthOfTravel) return

                this.pageSize.page += 1
                this.$apollo.queries.myTripsTravelTips.fetchMore({
                    variables: {
                        filter: this.filter,
                        pageSize: this.pageSize
                    },

                    updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (fetchMoreResult.myTripsTravelTips.length < SINGLE_PAGE_SIZE) {
                        this.isMoreToFetch = false
                    }

                    return {
                        myTripsTravelTips: [
                        ...previousResult.myTripsTravelTips,
                        ...fetchMoreResult.myTripsTravelTips,
                        ],
                    }
                    },
                })
            },
            setDefaultFilter() {
                this.filter.countries = []
                this.filter.themes = []
                this.filter.difficulties = []
                this.filter.transferTypes = []
                this.filter.minLengthOfTravel = 1
                this.filter.maxLengthOfTravel = this.$store.state.sliderMaxValue
                this.filter.searchString = null
                this.filter.liked = true
                this.filter.progressState = null

                this.refreshResult()
            },
            refreshResult() {
                this.hasFilterChanged = true
                this.isMoreToFetch = true
                this.pageSize.page = 0
                this.$apollo.queries.myTripsTravelTips.stop()
                this.$apollo.queries.myTripsTravelTips.start()
                this.isSidebarVisible = false
            },
            translate(val, array) {
                if (array == 'countries') {
                    if (this.countries.length < 1) return
                    let value = this.countries.find(value => value.code === val)[this.countryLocalization]
                    return value
                }
                let foundValue = this[array].find(value => value.value === val);
                if (foundValue) return foundValue.text;
                return null
            },
            setAllCountriesInTravelTips(travelTips) {
                if (!travelTips || travelTips.length == 0) return
                let allCountries = []
                travelTips.forEach (travelTip => {
                    travelTip.itineraryDays.forEach(day => {
                        day.itineraryItems.forEach(item => {
                        if (item.countries) {
                        item.countries.forEach(country => {
                            if (!allCountries.some(value => value.code === country.code)) {
                            allCountries.push(country)
                            }
                        }) }
                        })
                    })
                })
                this.countries = allCountries.sort((a, b) => {
                    return sharedUtils.removeAccents(a[this.countryLocalization]).localeCompare(sharedUtils.removeAccents(b[this.countryLocalization]), this.$store.state.preferredLanguage)
                })
            },

            setFilterState(state) {
                if (state === 'LIKED') {
                    this.filter.liked = true;
                    this.filter.progressState = null;
                } else if (state === 'IN_PROGRESS') {
                    this.filter.liked = null;
                    this.filter.progressState = 'IN_PROGRESS';
                } else if (state === 'FINISHED') {
                    this.filter.liked = null;
                    this.filter.progressState = 'FINISHED';
                }
            },
            setMaxNumberOfDays(travelTips) {
                let allDaysCount = 0
                travelTips.forEach(tip => allDaysCount = tip.dayCount > allDaysCount ? tip.dayCount : allDaysCount)
                this.$store.commit('setSliderMaxValue', allDaysCount);
                this.filter.maxLengthOfTravel = allDaysCount
            },
        },

        watch: {    
            'filter.difficulties' (newValue, oldValue) {
                this.cbAllSelected.difficulties = newValue.length === this.difficulties.length ? true : false
            },     
            'filter.themes' (newValue, oldValue) {
                this.cbAllSelected.themes = newValue.length === this.themes.length ? true : false
            },     
            'filter.countries' (newValue, oldValue) {
                if (!this.countries) return
                this.countriesSearchString = ''
                this.cbAllSelected.countries = newValue.length === this.countries.length ? true : false
            },
            filter: {
                handler(newValue, oldValue) {
                    this.pageSize.page = 0
                    this.hasFilterChanged = true
                    this.isMoreToFetch = true
                },
                deep: true
            },
        },

        mounted() {
          window.addEventListener('scroll', this.handleScroll)
        },
        destroyed() {
          window.removeEventListener('scroll', this.handleScroll)
        },

        apollo: {
            myTripsTravelTips: {
                query: MY_TRAVEL_TIPS,
                variables: function() {
                    return {
                        filter: {
                            countries: this.filter.countries,
                            themes: this.filter.themes,
                            difficulties: this.filter.difficulties,
                            transferTypes: this.filter.transferTypes,
                            minLengthOfTravel: this.filter.minLengthOfTravel,
                            maxLengthOfTravel: this.filter.maxLengthOfTravel,
                            searchString: this.filter.searchString,
                            liked: this.filter.liked,
                            progressState: this.filter.progressState
                        },
                        pageSize: {
                            page: 0,
                            size: SINGLE_PAGE_SIZE,
                            sortAttribute: "VIEW_COUNT",
                            sortDirection: "DESC"
                        }
                    }
                },
                skip() {
                    return !this.$store.state.sliderMaxValue
                },
                update(data) {
                    return data.myTripsTravelTips.map(travelTip => assignTravelTipLocalizationProperties(travelTip))  
                },
                result ({ data, loading, networkStatus }) {
                    this.hasFilterChanged = false
                    
                    if (data.myTripsTravelTips?.length < SINGLE_PAGE_SIZE) {
                        this.isMoreToFetch = false
                    }
                },
                fetchPolicy: 'cache-and-network',
            },
            myTravelTipsForCountries: {
                query: MY_TRAVEL_TIPS_FOR_COUNTRIES,
                result: function(resp) {
                    this.setAllCountriesInTravelTips(resp.data.myTravelTipsForCountries)
                }
            },
            travelTipsWithDaycountOnly: {
                query: TRAVEL_TIPS_WITH_DAYCOUNT_ONLY,
                result ({ data, loading, networkStatus }) {
                    this.setMaxNumberOfDays(data.travelTipsWithDaycountOnly)
                },
                skip() {
                    return this.$store.state.sliderMaxValue != undefined
                }
            },
        },

    }
</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    a:hover {
        text-decoration: none;
    }

    main {
        min-height: 700px;
    }

    .travel-tip-list-container {
        position: relative;
        max-width: 1810px;
        width: 100%;
        grid-gap: 50px 40px;
        margin-right: 40px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, auto));
    }

    .filter-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    @media (min-width: 899px){
        h1 {
            display: none;
        }

        .filter-buttons {
            margin-top: 46px;
        }
    }
    .page-loading-container {
        margin-top: 50px;
    }
    .filter-sidebar {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;

        .buttons-container {
            margin-top: 40px
        }
    }
    .filter-icon-container {
        cursor: pointer;
        position: relative;

        .filter-icon {
            height: 30px;
            width: 30px;
        }

        .filter-count {
            position: absolute;
            top: 17px;
            left: -7px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            background-color: $tr-green;
        }
    }

    .state-pick-button {
        background-color: transparent;
        border: 1px solid transparent;
        padding-inline: 12px;
        height: 30px;

        &.active {
            color: $tr-white;
            background-color: $tr-black;
        }
    }
    .no-travel-tips-alert-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 400px;

        p {
            margin: 0;
            max-width: 340px;
            text-align: center;
            white-space: pre-line;

            &:first-of-type {
                color: #A1ABB3;
            }
        }

        a, button {
            margin-top: 24px;
            width: 330px;
        }
    }
</style>
