<template>
    <div class="sidebar-container" :class="{active: isVisible}">
        <div class="sidebar-backdrop" @click="$emit('closeSidebar')"></div>
        <div class="sidebar-background">
            <p v-if="title" class="sidebar-title px-40">{{ title }}</p>
            <button class="close-menu" @click="$emit('closeSidebar')"><img src="@/assets/svg/icon-close.svg" alt=""></button>
            <slot></slot>
        </div>
    </div>
</template>
  
<script>

    export default {
        name: 'Sidebar',
        props: {
            isVisible: {
                type: Boolean,
                default: false,
            },
            title: String
        },

        watch: {
            isVisible(nV) {
                if (nV) {
                    document.body.style.overflow = 'hidden'
                } else {
                    document.body.style.overflow = "auto"
                }
            }
        },

        unmounted() {
            document.body.overflow = 'auto'
        },

        methods: {
            openMenu() {

            }
        }
    };


</script>

<style scoped lang="scss">
    @import '@/scss/variables';

    .px-40 {
        padding-inline: 40px;
    }

.sidebar-container {
    height: 100vh;
    height: 100svh;
    height: -webkit-fill-available;
    width: 100vw;
    visibility: hidden;
    top: 0;
    position: fixed;
    left: 0;
    z-index: 1100;
    opacity: 0;
    display: flex;
    transition: visibility 0.2s ease, opacity 0.2s ease;
    background: rgba(0, 0, 0, 0.4);

    .sidebar-backdrop {
        width: 100%;
    }

    
    .close-menu {
      position: absolute;
      width: 50px;
      height: 50px;
      top: 17px;
      right: 17px;
      padding: 0;
      background-color: transparent;
      border: none;
      z-index: 1;
    }
    
    .sidebar-background {
        position: relative;
        margin-left: auto;
        padding-top: 90px;
        padding-bottom: 20px;
        min-width: 400px;
        right: -400px !important;
        height: 100%;
        list-style: none;
        background-color: $tr-white;
        color: $tr-black;
        transition: right 200ms ease;
        box-shadow: -6px 0px 20px 10px rgba(0, 0, 0, 0.15);
        overflow: hidden;

        @media (max-width: 576px) {
            min-width: 90vw;
        }

        .sidebar-title {
            position: absolute;
            top: 30px;
        }
    }
    &.active {
        visibility: visible;
        opacity: 1;
    
        .sidebar-background {
            right: 0 !important;
        }
    }
}
</style>
  